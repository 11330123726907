import React, { useCallback, useEffect, useState } from 'react';
import './users.scss';
import { GetWord } from "../../../../utils";
import { Space, Table, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import Service from '../../../../Service';
import moment from 'moment';
import 'moment/locale/tr';
moment().locale('tr');

const Users = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [listData, setListData] = useState(null);
    const navigate = useNavigate();

    const getUserListData = useCallback(() => {
        const service = new Service();
        const offset = (currentPage - 1) * pageSize;
        service.AdminService.getUserList(`limit=${pageSize}&offset=${offset}`).then((res) => {
            setListData(res);
        });
    }, [currentPage]);

    useEffect(() => {
        getUserListData();
    }, [getUserListData]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const columns = [
        {
            title: GetWord('KULLANICI ADI & FOTOĞRAFI'),
            dataIndex: 'name',
            render: (text, data) => (
                <div className='users-table-users'>
                    <div className='users-table-users-profilephoto'>
                        <img src={data?.image} alt='' className='users-table-users-profilephoto' />
                    </div>
                    <span className='users-table-users-text'>{text}</span>
                </div>
            ),
        },
        {
            title: GetWord('E-POSTA'),
            dataIndex: 'email',
            render: (title) => (
                <div className='users-table-email'>
                    <span className='users-table-email-title'>{title}</span>
                </div>
            ),
        },
        {
            title: <div className='users-table-date-title'>{GetWord('KAYIT TARİHİ')}</div>,
            dataIndex: 'creationDate',
            render: (date) => (
                <div className='users-table-date'>
                    <span className='users-table-date-date'>{moment(date).format("L")}</span>
                    <span className='users-table-date-time'>{moment(date).format("LT")}</span>
                </div>
            ),
            sorter: (a, b) => new Date(a.creationDate) - new Date(b.creationDate),
        },
        {
            title: GetWord('YAŞ'),
            dataIndex: 'dob',
            render: (age) => (
                <div className='users-table-age'>
                    <span className='users-table-age-text'>{age}</span>
                </div>
            ),
        },
        {
            title: GetWord('ARAÇLARI'),
            dataIndex: 'vehicles',
            render: (vehicles) => (
                <button className='users-table-cars'>
                    <span className='users-table-cars-text'>
                        {GetWord('Görüntüle')} ({vehicles ? vehicles.length : 0})
                    </span>
                </button>
            ),
        },
        {
            title: GetWord('SEÇENEKLER'),
            render: (_, data) => (
                <Space size="middle">
                    <button className='users-table-options-view' onClick={() => navigate("userdetails/" + data.uid)}>
                        <i className='icon-Eye' />
                    </button>
                    <button className='users-table-options-edit'>
                        <i className='icon-Edit' />
                    </button>
                    <button className='users-table-options-block'>
                        <i className='icon-Error' />
                    </button>
                    <button className='users-table-options-delete'>
                        <i className='icon-Delete' />
                    </button>
                </Space>
            ),
        },
    ];

    return (
        <div className='users'>
            <div className='users-header'>
                <p className='users-header-title'>
                    {GetWord('Kullanıcılar')}
                </p>
            </div>

            <div className='users-table'>
                <Table
                    columns={columns}
                    dataSource={listData?.users}
                    pagination={false}
                />
            </div>

            <div className='pagination-container'>
                <span className='pagination-label'>{GetWord('Sayfa')}</span>
                <Pagination
                    className='users-pagination'
                    current={currentPage}
                    pageSize={pageSize}
                    total={listData?.totalCount}
                    onChange={handlePageChange}
                />
            </div>
        </div>
    );
}

export default Users;
