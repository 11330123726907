import React, { useCallback, useState } from 'react'
import './weeklycar.scss'
import { GetWord } from "../../../../utils";
import { Space, Table, Pagination } from 'antd';
import Service from '../../../../Service';

const columns = [
    {
        title: GetWord('KULLANICI ADI & FOTOĞRAFI'),
        dataIndex: 'username',
        key: 'username',
        render: (text) => <div className='weeklycar-table-users'>
            <div className='weeklycar-table-users-profilephoto'>

            </div >
            <span className='weeklycar-table-users-text'>{text}</span>
        </div >,
    },
    {
        title: GetWord('HİKAYE'),
        dataIndex: 'story',
        key: 'story',
        render: (title, data) =>
            <button className='weeklycar-table-story'>
                <i className='icon-Eye weeklycar-table-story-icon' />
                <span className='weeklycar-table-story-text'>{GetWord('Görüntüle')}</span>
            </button>
        ,
    },
    {
        title: <div className='weeklycar-table-date-title'>{GetWord('PAYLAŞIM TARİHİ')}</div>,
        dataIndex: 'date',
        key: 'date',
        render: (date, data) => <div className='weeklycar-table-date'>
            <span className='weeklycar-table-date-date'>{date}</span>
            <span className='weeklycar-table-date-time'>{data.time}</span>
        </div >,
        sorter: (a, b) => a.date - b.date,
    },

    {
        title: GetWord('SEÇENEKLER'),
        key: 'options',
        render: (_, record) => (
            <Space size="middle">
                <button className='weeklycar-table-options-block'>
                    <i className='icon-Error' />
                </button>
                <button className='weeklycar-table-options-delete'>
                    <i className='icon-Delete' />
                </button>
            </Space>
        ),
    },
];

const data = [
    {
        key: '1',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '2',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        typeSmall: '3 Seçenek',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '3',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '4',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '5',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        typeSmall: '3 Seçenek',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '6',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '7',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '8',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        typeSmall: '3 Seçenek',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '9',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '10',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '11',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        typeSmall: '3 Seçenek',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '12',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '13',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '14',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        typeSmall: '3 Seçenek',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
    {
        key: '15',
        username: 'Berkant Kalender',
        email: 'info@petrosapp.com',
        date: '05/28/2024',
        time: '12:00',
        age: 25,
        cars: 1,
    },
];


const Weeklycar = () => {
    const [currentPage, setCurrentPage] = useState(1); // Şu anki sayfayı yönetmek için state
    const pageSize = 10; // Sayfa başına gösterilecek öğe sayısı
    const [userList, setUserList] = useState(null);

    const getUserListData = useCallback(() => {
        const service = new Service();
        Service.AdminService.getUserList().then((res) => {
            setUserList(res);
        })
    })

    const handlePageChange = (page) => {
        setCurrentPage(page); // Sayfa değişikliğinde yeni sayfayı ayarla
    };
    const currentPageData = data.slice((currentPage - 1) * pageSize, currentPage * pageSize);


    return (
        <div className='weeklycar'>
            <div class="weeklycar-header">
                <p className='weeklycar-header-title'>
                    {GetWord('Haftanın Araçları')}
                </p>
                <button class="weeklycar-header-button">
                    <i className='icon-Plus weeklycar-header-button-icon' />
                    <span className='weeklycar-header-button-text'>
                        {GetWord('Araç Ekle')}
                    </span>
                </button>
            </div>

            <div className='weeklycar-table'>
                <Table columns={columns} dataSource={currentPageData} // dataSource'u currentPageData olarak ayarlayın
                    pagination={false}
                />
            </div>

            <div className='pagination-container'>
                <span className='pagination-label'>{GetWord('Sayfa')} </span>
                <Pagination
                    className='weeklycar-pagination'
                    current={currentPage}
                    pageSize={pageSize}
                    total={data.length}
                    onChange={handlePageChange}
                />
            </div>
        </div>
    )
}

export default Weeklycar;
