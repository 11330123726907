import React, { useCallback, useEffect, useState } from 'react'
import './userdetails.scss'
import { Modal, Tabs } from 'antd';
import Overview from './components/overview/overview';
import Security from './components/security/security';
import Eventhistory from './components/eventhistory/eventhistory';
import Editprofile from './components/editprofile';
import { GetWord } from '../../../../../utils';
import { useParams } from 'react-router-dom';
import Service from '../../../../../Service';
import moment from 'moment';

const Userdetails = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [isRefresh, setIsRefreh] = useState(false);
    const [isShowEditProfileModalOpen, setShowEditProfileModalOpen] = useState(false);
    const [showDetail, setShowDetail] = useState();


    const userDetailData = useCallback(() => {
        const service = new Service();
        service.AdminService.getUserDetail(id).then((res) => {
            console.log(res);
            setData(res);
        })
    }, [id])

    useEffect(() => {
        userDetailData()
    }, [userDetailData, isRefresh])



    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onChange = (key) => {
        console.log(key);
    };

    const showEditProfileModal = () => {
        setShowEditProfileModalOpen(true);
    };
    const handleOk = () => {
        setShowEditProfileModalOpen(false);
    };

    const handleCancel = () => {
        setShowEditProfileModalOpen(false);
    };


    const items = [
        {
            key: '1',
            label: GetWord('Genel Bakış'),
            children: <Overview data={data} />,
        },
        {
            key: '2',
            label: GetWord('Güvenlik'),
            children: <Security />,
        },
        {
            key: '3',
            label: GetWord('Etkinlik Geçmişi'),
            children: <Eventhistory />,
        },
    ];


    return (
        <div className='userdetails'>
            <div className='userdetails-header'>
                <button className='userdetails-header-backbutton'>
                    <i className='icon-Arrow-Left userdetails-header-backbutton-icon' />
                </button>
                <span className='userdetails-header-prevpage'>{GetWord('Kullanıcılar')}</span>
                <i className='icon-Arrow-Right userdetails-header-arrowright' />
                <span className='userdetails-header-title'>{GetWord('Kullanıcı Profili')}</span>
            </div>

            <div className='userdetails-body'>
                <div className='userdetails-body-leftarea'>
                    <div className='userdetails-body-leftarea-profilarea'>
                        <img className='userdetails-body-leftarea-profilarea-photo' src={data?.image} alt='' />
                        <span className='userdetails-body-leftarea-profilarea-namesurname'>{data?.name}</span>
                    </div>
                    <div className='userdetails-body-leftarea-detailarea'>
                        <button onClick={() => {
                            setShowDetail((prev) => !prev)
                        }}
                            className='userdetails-body-leftarea-detailarea-detail'>
                            <span className='userdetails-body-leftarea-detailarea-detail-text'>
                                {GetWord('Detay')}:
                            </span>
                            {
                                showDetail ?
                                    <>
                                        <i className='icon-Arrow-Up userdetails-body-leftarea-detailarea-detail-icon' />
                                    </> :
                                    <>
                                        <i className='icon-Arrow-Down userdetails-body-leftarea-detailarea-detail-icon' />
                                    </>
                            }

                        </button>
                        <button className='userdetails-body-leftarea-detailarea-button' onClick={showEditProfileModal}>
                            <i className='icon-Edit userdetails-body-leftarea-detailarea-button-icon' />
                            <span className='userdetails-body-leftarea-detailarea-button-text'>
                                {GetWord('Düzenle')}
                            </span>
                        </button>
                    </div>
                    <div className='userdetails-modal-editprofile'>
                        <Modal destroyOnClose={true} title={GetWord('Kullanıcı Bilgilerini Güncelle')} open={isShowEditProfileModalOpen} onOk={handleOk} onCancel={handleCancel} className='userdetails-modal'
                            footer={false}>
                            <Editprofile selectedData={data} setIsRefreh={setIsRefreh} setShowEditProfileModalOpen={setShowEditProfileModalOpen} />
                        </Modal>
                    </div>
                    {
                        showDetail ?
                            <>

                                <div className='userdetails-body-leftarea-accont'>
                                    <span className='userdetails-body-leftarea-accont-title'>{GetWord('Hesap ID')}</span>
                                    <span className='userdetails-body-leftarea-accont-text'>{data?.uid}</span>
                                </div>
                                <div className='userdetails-body-leftarea-email'>
                                    <span className='userdetails-body-leftarea-email-title'>{GetWord('E-Posta')}</span>
                                    <span className='userdetails-body-leftarea-email-text'>{data?.email}</span>
                                </div>

                                <div className='userdetails-body-leftarea-vehicles'>
                                    <span className='userdetails-body-leftarea-vehicles-title'>{GetWord('Araç Sayısı')}</span>
                                    <span className='userdetails-body-leftarea-vehicles-text'>{data?.vehicleCount}</span>
                                    <button className='userdetails-body-leftarea-vehicles-button' >
                                        <i className='icon-Eye userdetails-body-leftarea-vehicles-button-icon' />
                                        <span className='userdetails-body-leftarea-vehicles-button-text'>{GetWord('Görüntüle')}</span>
                                    </button>
                                </div>

                                <div className='userdetails-body-leftarea-comment'>
                                    <span className='userdetails-body-leftarea-comment-title'>{GetWord('Yorumlar & Değerlendirmeler')}</span>
                                    <span className='userdetails-body-leftarea-comment-text'>{data?.feedbackCount + data?.commentCount}</span>
                                    <button className='userdetails-body-leftarea-comment-button' >
                                        <i className='icon-Eye userdetails-body-leftarea-comment-button-icon' />
                                        <span className='userdetails-body-leftarea-comment-button-text'>{GetWord('Görüntüle')}</span>
                                    </button>
                                </div>

                                <div className='userdetails-body-leftarea-finalentry'>
                                    <span className='userdetails-body-leftarea-finalentry-title'>{GetWord('Son Giriş')}</span>
                                    <span className='userdetails-body-leftarea-finalentry-text'>{moment(data?.lastUpdated).format('LL')}</span>
                                </div>
                            </> : null
                    }
                </div>



                <div className='userdetails-body-rightarea'>

                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />

                </div>
            </div>
        </div>
    )
}

export default Userdetails