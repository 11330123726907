import { Table, Tag } from 'antd'
import React from 'react'
import Login from './components/login'
import Daily from './components/daily'
import Activities from './components/activities'

const Eventhistory = () => {

    return (
        <div className='userdetails-body-rightarea-eventhistory'>
            <Login />
            <Daily />
            <Activities />
        </div>
    )
}

export default Eventhistory