import { Button, DatePicker, Form, Input, Select, TimePicker } from 'antd'
import React, { useCallback, useState } from 'react'
import '../../notification.scss'
import TextArea from 'antd/es/input/TextArea'
import { GetWord } from '../../../../../../utils'
import moment from 'moment'
import Service from '../../../../../../Service'


const Public = ({ setIsRefresh, setModal }) => {

    const postNotificationGeneral = useCallback((value) => {
        const service = new Service()

        service.NotificationService.postNotificationGeneral(value).then((res) => {
            if (!res.error) {
                setIsRefresh((prev) => !prev);
                setModal((prev) => !prev);
            } else {

            }
        })
    }, [setIsRefresh, setModal])


    const timeformat = 'HH:mm';
    const dateFormat = 'DD/MM/YYYY';
    const today = moment().format(dateFormat);
    const currentTime = moment().format(timeformat);

    return (
        <div className='notification-modal-public'>
            <Form onFinish={postNotificationGeneral}>
                <div className='notification-modal-public-form'>
                    <div className='notification-modal-public-form-titleimage'>
                        <Form.Item name="title" className='notification-modal-public-input' rules={[{
                            required: true,
                            message: "Başlık zorunlu alan"
                        }]}
                        >
                            <Input placeholder={GetWord('Başlık')} />
                        </Form.Item>
                        <Form.Item className='notification-modal-public-url'
                            name="image"
                            rules={[
                                {
                                    required: true,
                                    message: GetWord("resim girmek zorunludur")
                                },
                                {
                                    type: 'url',
                                    warningOnly: true,
                                },
                                {
                                    type: 'string',
                                    min: 6,
                                },
                            ]}
                        >
                            <Input placeholder={GetWord('Görsel URL')} />
                        </Form.Item>
                    </div>
                    <div className='notification-modal-public-form-content'>
                        <Form.Item name="body" className='notification-modal-public-textarea'>
                            <TextArea rows={4}
                                placeholder={GetWord('İçerik')}
                                style={{
                                    resize: 'none',
                                }}
                            />
                        </Form.Item>
                    </div>
                </div>

                <Form.Item className='notification-modal-public-url'
                    name="url"
                    rules={[
                        {
                            required: true,
                            message: GetWord("url girmek zorunludur")
                        },
                        {
                            type: 'url',
                            warningOnly: true,
                        },
                        {
                            type: 'string',
                            min: 6,
                        },
                    ]}
                >
                    <Input placeholder={GetWord('URL')} />
                </Form.Item>

                <div className='notification-modal-public-datetime'>
                    {/* <span className='notification-modal-public-datetime-title'>
                        {GetWord('Gönderilecek Tarih & Saat')}
                    </span> */}
                    <div className='notification-modal-public-datetime-datetimearea'>
                        <div className='notification-modal-public-datetime-datetimearea-datearea'>
                            <span className='notification-modal-public-datetime-datetimearea-datearea-title'>
                                {GetWord('Ekrana Yönlendir')}
                            </span>

                            <Form.Item name="navigationScreen" className='notification-modal-campaign-targetgroup-selectarea-brandarea-select' rules={[{
                                required: true,
                                message: "Ekran yönlendirme zorunlu alan"
                            }]}>
                                <Select
                                    options={[
                                        { value: 'Notifications', label: 'Notifications' },
                                        { value: 'FuelPrices', label: 'FuelPrices' },
                                    ]}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-public-datetime-datetimearea-timearea'>
                            <span className='notification-modal-public-datetime-datetimearea-timearea-title'>
                                {GetWord('Tür')}
                            </span>

                            <Form.Item name="type" className='notification-modal-campaign-targetgroup-selectarea-brandarea-select' rules={[{
                                required: true,
                                message: "Tür zorunlu alan"
                            }]}>
                                <Select
                                    options={[
                                        { value: 'general', label: 'general' },
                                        { value: 'station', label: 'station' },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <Form.Item className='notification-modal-public-sendbutton'>
                    <Button htmlType='submit' type="primary" className='notification-modal-public-sendbutton-style'>
                        <span className='notification-modal-public-sendbutton-text'>
                            {GetWord('Gönder')}</span>
                    </Button>
                </Form.Item>

            </Form>




        </div>
    )
}

export default Public