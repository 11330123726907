import { Table } from 'antd'
import React from 'react'
import { GetWord } from '../../../../../../../../utils';

const Activities = () => {

    const ActivitiesColumns = [
        {
            title: GetWord('İŞLEM'),
            dataIndex: 'process',
            key: 'process',
            render: (text) => <div className='userdetails-body-rightarea-eventhistory-daily-table-process'>
                <span className='userdetails-body-rightarea-eventhistory-daily-table-process-text'>{text}</span>
            </div >,
        },

        {
            title: <div className='userdetails-body-rightarea-eventhistory-daily-table-date-title'>{GetWord('TARİH & SAAT')}</div>,
            dataIndex: 'date',
            key: 'date',
            render: (date, data) => <div className='userdetails-body-rightarea-eventhistory-daily-table-date'>
                <span className='userdetails-body-rightarea-eventhistory-daily-table-date-date'>{date}</span>
                <span className='userdetails-body-rightarea-eventhistory-daily-table-date-time'>{data.time}</span>
            </div >,
            sorter: (a, b) => a.date - b.date,
        },


    ];

    const ActivitiesData = [
        {
            key: '1',
            process: 'Sean Bean has made payment to #XRS-45670',
            date: '05/28/2024',
            time: '12:00',
        },
        {
            key: '2',
            process: 'Brian Cox has made payment to #OLP-45690',
            date: '05/28/2024',
            time: '12:00',
        },
        {
            key: '3',
            process: 'Brian Cox has made payment to #OLP-45690',
            date: '05/28/2024',
            time: '12:00',
        },
        {
            key: '4',
            process: 'Sean Bean has made payment to #XRS-45670',
            date: '05/28/2024',
            time: '12:00',
        },
        {
            key: '5',
            process: 'Brian Cox has made payment to #OLP-45690',
            date: '05/28/2024',
            time: '12:00',
        },
        {
            key: '6',
            process: 'Brian Cox has made payment to #OLP-45690',
            date: '05/28/2024',
            time: '12:00',
        },


    ];

    return (
        <div className='userdetails-body-rightarea-eventhistory-activities'>
            <div className='userdetails-body-rightarea-eventhistory-activities-title'>
                <span className='userdetails-body-rightarea-eventhistory-activities-title-text'>
                    {GetWord('Aktiviteler')}
                </span>
                <button className='userdetails-body-rightarea-eventhistory-activities-title-button'>
                    <i className='icon-Check-Out userdetails-body-rightarea-eventhistory-activities-title-button-icon' />
                    <span className='userdetails-body-rightarea-eventhistory-activities-title-button-text'>
                        {GetWord('Raporu İndir')}
                    </span>
                </button>
            </div>
            <div className='userdetails-body-rightarea-eventhistory-activities-table'>
                <Table columns={ActivitiesColumns} dataSource={ActivitiesData}
                    pagination={{
                        pageSize: 3,
                    }}
                    footer={() => (
                        <button className='userdetails-body-rightarea-eventhistory-activities-table-footerbutton' >
                            <span className='userdetails-body-rightarea-eventhistory-activities-table-footerbutton-text'>{GetWord('Daha Fazla Göster')}</span>
                        </button>
                    )}
                />
            </div>
        </div>
    )
}

export default Activities