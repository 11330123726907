import { Table, Tag } from 'antd';
import React from 'react'
import { GetWord } from '../../../../../../../../utils';

const Login = () => {

    const LoginColumns = [
        {
            title: GetWord('KONUM'),
            dataIndex: 'location',
            key: 'location',
            render: (text) => <div className='userdetails-body-rightarea-eventhistory-loginsessions-table-location'>
                <span className='userdetails-body-rightarea-eventhistory-loginsessions-table-location-text'>{text}</span>
            </div >,
        },

        {
            title: GetWord('CİHAZ'),
            dataIndex: 'device',
            key: 'device',
            render: (text) => <div className='userdetails-body-rightarea-eventhistory-loginsessions-table-device'>
                <span className='userdetails-body-rightarea-eventhistory-loginsessions-table-device-text'>{text}</span>
            </div >,
        },
        {
            title: <div className='userdetails-body-rightarea-eventhistory-loginsessions-table-date-title'>  {GetWord('TARİH & SAAT')}</div>,
            dataIndex: 'date',
            key: 'date',
            render: (date, data) => <div className='userdetails-body-rightarea-eventhistory-loginsessions-table-date'>
                <span className='userdetails-body-rightarea-eventhistory-loginsessions-table-date-date'>{date}</span>
                <span className='userdetails-body-rightarea-eventhistory-loginsessions-table-date-time'>{data.time}</span>
            </div >,
            sorter: (a, b) => a.date - b.date,
        },

        {
            title: GetWord('IP ADRESİ'),
            dataIndex: 'ipaddress',
            key: 'ipaddress',
            render: (text) => <div className='userdetails-body-rightarea-eventhistory-loginsessions-table-ipaddress'>
                <span className='userdetails-body-rightarea-eventhistory-loginsessions-table-ipaddress-text'>{text}</span>
            </div >,
        },

        {
            title: GetWord('AKSİYON'),
            key: 'action',
            dataIndex: 'action',
            render: (_, { action }) => (
                <>
                    {action.map((action) => {
                        let color = action.length > 5 ? 'geekblue' : 'green';
                        if (action === GetWord('Açık Oturum')) {
                            color = 'cyan';
                        }
                        if (action === GetWord('Zaman Aşımı')) {
                            color = 'yellow';
                        }
                        if (action === GetWord('Çıkış Yaptı')) {
                            color = 'red';
                        }
                        return (
                            <Tag color={color} key={action}>
                                {action}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
    ];

    const LoginData = [
        {
            key: '1',
            location: 'İstanbul',
            device: 'iPhone 12 Pro',
            date: '05/28/2024',
            time: '12:00',
            ipaddress: '207.32.11.274',
            action: [GetWord('Açık Oturum')],
        },
        {
            key: '2',
            location: 'İstanbul',
            device: 'Samsung Galaxy S23 +',
            date: '05/28/2024',
            time: '12:00',
            ipaddress: '207.32.11.274',
            action: [GetWord('Zaman Aşımı')],
        },
        {
            key: '3',
            location: 'İstanbul',
            device: 'iPhone 12 Pro',
            date: '05/28/2024',
            time: '12:00',
            ipaddress: '207.32.11.274',
            action: [GetWord('Çıkış Yaptı')],
        },
    ];

    return (
        <div className='userdetails-body-rightarea-eventhistory-loginsessions'>
            <div className='userdetails-body-rightarea-eventhistory-loginsessions-title'>
                <span className='userdetails-body-rightarea-eventhistory-loginsessions-title-text'>
                    {GetWord('Giriş Oturumları')}
                </span>
                <button className='userdetails-body-rightarea-eventhistory-loginsessions-title-button'>
                    <i className='icon-Check-Out userdetails-body-rightarea-eventhistory-loginsessions-title-button-icon' />
                    <span className='userdetails-body-rightarea-eventhistory-loginsessions-title-button-text'>
                        {GetWord('Tüm Cihazlardan Çıkış Yap')}
                    </span>
                </button>
            </div>
            <div className='userdetails-body-rightarea-eventhistory-loginsessions-table'>
                <Table columns={LoginColumns} dataSource={LoginData}
                    pagination={{
                        pageSize: 3,
                    }}
                    footer={() => (
                        <button className='userdetails-body-rightarea-eventhistory-loginsessions-table-footerbutton' >
                            <span className='userdetails-body-rightarea-eventhistory-loginsessions-table-footerbutton-text'>    {GetWord('Daha Fazla Göster')}</span>
                        </button>
                    )}
                />
            </div>
        </div>
    )
}

export default Login