import baseService from "./baseService";

export default class StoriesService extends baseService {
  constructor() {
    super();
    this.schemaName = "StoriesService";
  }
  getStories() {
    return this.getObject("api", "getStories");
  }

  addStory(entity) {
    return this.postObject("api", "addStory", entity);
  }
  deleteStory() {
    return this.deleteObject("api", "deleteStory");
  }
}
