import { Button, Card, Form, Input, List, Tag } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React from 'react'
import { GetWord } from '../../../../../../utils'

const Notificationdetail = () => {



    const Typedata = [
        {
            title: 'Kampanya',
        },
        {
            title: '1 Haftadır Masraf Girişi Yapmayan',
        },
        {
            title: 'Motor Sahiplerine',
        },
        {
            title: 'Motor Sahiplerine',
        },
        {
            title: 'Motor Sahiplerine',
        },
        {
            title: 'Motor Sahiplerine',
        },
    ];

    return (
        <div>
            <div className='notification-notificationdetailmodal'>
                <Form>
                    <div className='notification-notificationdetailmodal-notificationtitlearea'>
                        <span className='notification-notificationdetailmodal-notificationtitlearea-title'>
                            {GetWord('Bildirim Başlığı')}:
                        </span>
                        <Form.Item className='notification-notificationdetailmodal-notificationtitlearea-input'>
                            <Input placeholder={GetWord('Başlık')} />
                        </Form.Item>
                    </div>
                    <div className='notification-notificationdetailmodal-explanationarea'>
                        <span className='notification-notificationdetailmodal-explanationarea-title'>
                            {GetWord('Açıklama')}:
                        </span>
                        <Form.Item className='notification-notificationdetailmodal-explanationarea-textarea'>
                            <TextArea rows={4}
                                placeholder={GetWord('İçerik')}
                                style={{
                                    resize: 'none',
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className='notification-notificationdetailmodal-urlarea'>
                        <span className='notification-notificationdetailmodal-urlarea-title'>
                            {GetWord('Görsel')}:
                        </span>
                        <Form.Item className='notification-notificationdetailmodal-urlarea-url'
                            name="url"
                            rules={[
                                {
                                    required: true,
                                },
                                {
                                    type: 'url',
                                    warningOnly: true,
                                },
                                {
                                    type: 'string',
                                    min: 6,
                                },
                            ]}
                        >
                            <Input placeholder={GetWord('Görsel URL')} />
                        </Form.Item>
                    </div>
                    <div className='notification-notificationdetailmodal-typearea'>
                        <span className='notification-notificationdetailmodal-typearea-title'>
                            {GetWord('Tür & Kitle')}:
                        </span>

                        <List
                            grid={{
                                rows: 1,
                            }}
                            dataSource={Typedata}
                            renderItem={(item) => (
                                <List.Item>
                                    <Card title={item.title} />
                                </List.Item>
                            )}
                        />


                    </div>

                    <div className='notification-notificationdetailmodal-releasearea'>
                        <span className='notification-notificationdetailmodal-releasearea-title'>
                            {GetWord('Yayınlanma Tarihi')}:
                        </span>
                        <div className='notification-notificationdetailmodal-releasearea-releasedate'>
                            <div className='notification-notificationdetailmodal-releasearea-releasedate-datearea'>
                                <span className='notification-notificationdetailmodal-releasearea-releasedate-datearea-date'>
                                    05/28/2024
                                </span>
                                <span className='notification-notificationdetailmodal-releasearea-releasedate-datearea-time'>
                                    12:00
                                </span>
                            </div>

                            <div className='notification-notificationdetailmodal-releasearea-releasedate-statusarea'>
                                {(() => {
                                    let color = 'green';
                                    const currentStatus = GetWord('Gönderildi');
                                    if (currentStatus === GetWord('Gönderildi')) {
                                        color = 'cyan';
                                    } else if (currentStatus === GetWord('Yayınlanacak')) {
                                        color = 'yellow';
                                    } else if (currentStatus === GetWord('Silindi')) {
                                        color = 'red';
                                    }

                                    return (
                                        <Tag color={color} key={currentStatus}>
                                            {currentStatus}
                                        </Tag>
                                    );
                                })()}
                            </div>

                        </div>
                    </div>
                </Form >


            </div >
            <div className='notification-notificationdetailmodal-buttonarea'>
                <Button className='notification-notificationdetailmodal-buttonarea-deletebutton'>
                    <span className='notification-notificationdetailmodal-buttonarea-deletebutton-text'>
                        {GetWord('Sil')}</span>
                    <i className='icon-Delete notification-notificationdetailmodal-buttonarea-deletebutton-icon' />
                </Button>
                <Button className='notification-notificationdetailmodal-buttonarea-editbutton'>
                    <span className='notification-notificationdetailmodal-buttonarea-editbutton-text'>
                        {GetWord('Düzenle')}</span>
                    <i className='icon-Edit notification-notificationdetailmodal-buttonarea-editbutton-icon' />
                </Button>
            </div>
        </div >
    )
}

export default Notificationdetail