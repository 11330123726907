import React, { useCallback, useEffect, useState } from 'react'
import '../stations.scss'
import { GetWord } from '../../../../../utils'
import { Flex, Progress, Rate } from 'antd'
import Service from '../../../../../Service'
import moment from 'moment'

const Commentscount = ({ selectedId }) => {

  const [commentsData, setcommentsData] = useState(null);

  const getCommentsData = useCallback(() => {
    const service = new Service();
    service.AdminService.getStationComments(selectedId).then((res) => {
      res.combinedRatingCounts = res.combinedRatingCounts.reverse();
      setcommentsData(res);
    })
  }, [selectedId]);

  useEffect(() => {
    getCommentsData()
  }, [getCommentsData])

  return (
    <div className='stations-modal'>
      <div className='stations-modal-ratearea'>
        <div className='stations-modal-ratearea-average'>
          <span className='stations-modal-ratearea-average-title'>
            {GetWord("Ortalama")}
          </span>
          <div className='stations-modal-ratearea-average-countarea'>
            <span className='stations-modal-ratearea-average-countarea-rate'>
              {commentsData?.averageRating}
            </span>
            <span className='stations-modal-ratearea-average-countarea-count'>
              {commentsData?.totalFeedbacks}
            </span>
          </div>

          {commentsData && commentsData.averageRating && <Rate disabled allowHalf defaultValue={parseFloat(commentsData?.averageRating)} />}
        </div>
        <div className='stations-modal-ratearea-distribution'>
          <span className='stations-modal-ratearea-distribution-title'>
            {GetWord("Dağılım")}
          </span>
          <Flex
            vertical
            gap="small"
            style={{
              width: 250
            }}
          >
            {commentsData && commentsData.combinedRatingCounts.map((item, index) => {
              const percent = (item.count / (commentsData?.totalFeedbacks)) * 100;
              return (
                <div key={index} className='stations-modal-ratearea-distribution-flex'>
                  <span className='stations-modal-ratearea-distribution-flex-number'>{item.rating}</span>
                  <Progress percent={percent} showInfo={false} size="small" className='stations-modal-ratearea-distribution-flex-line' />
                  <span className='stations-modal-ratearea-distribution-flex-count'>{item.count}</span>
                </div>
              )
            })}

          </Flex>
        </div>
      </div>
      <div className='stations-modal-stroke' />
      <div className='stations-modal-commentarea'>
        <div className='stations-modal-commentarea-title'>
          <span className='stations-modal-commentarea-title-text'>
            {GetWord("Yorumlar")}
          </span>
          <span className='stations-modal-commentarea-title-count'>
            {commentsData?.totalCommentCount}
          </span>
        </div>

        {commentsData && commentsData.last3Comments.map((item, index) => {
          return (
            <div className='stations-modal-commentarea-comment'>
              <div className='stations-modal-commentarea-comment-profilearea'>
                <div className='stations-modal-commentarea-comment-profilearea-profile'>
                  <img className='stations-modal-commentarea-comment-profilearea-profile-photo' src={item?.image}
                    alt='' />
                  <span className='stations-modal-commentarea-comment-profilearea-profile-name'>{item?.users.name}</span>
                </div>
                <button className='stations-modal-commentarea-comment-profilearea-moreicon'>
                  <i className='icon-More' />
                </button>
              </div>
              <div className='stations-modal-commentarea-comment-average'>
                <span className='stations-modal-commentarea-comment-average-date'>{moment(item?.creationDate).format('LL')}</span>
              </div>

              <span className='stations-modal-commentarea-comment-comments-text'>
                {item?.comment}
              </span>
            </div>

          )
        })}

        <div className='stations-modal-commentarea-comment-morebutton'>
          <button className='stations-modal-commentarea-comment-morebutton-button'>
            <span className='stations-modal-commentarea-comment-morebutton-button-text'>
              Daha Fazla Yorum Göster
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Commentscount