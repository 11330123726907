import React, { useCallback, useEffect, useState } from 'react'
import './stories.scss'
import { GetWord } from "../../../../utils";
import { Space, Table, Pagination, Modal } from 'antd';
import Service from '../../../../Service';
import Viewstory from './components/viewstory';
import moment from 'moment';

const Stories = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [openViewStory, setOpenViewStory] = useState(false);
    const [data, setData] = useState(null);
    const [selectedStory, setSelectedStory] = useState(null);

    const getStoryListData = useCallback(() => {
        const service = new Service();
        const offset = (currentPage - 1) * pageSize;
        service.AdminService.getStoryList(`limit=${pageSize}&offset=${offset}`).then((res) => {
            setData(res);
        })
    }, [currentPage]);

    useEffect(() => {
        getStoryListData();
    }, [getStoryListData]);

    const currentPageData = data?.data || [];

    const viewStoryOpen = (data) => {
        setSelectedStory(data);
        setOpenViewStory(true);
    };

    const noCancel = () => {
        setOpenViewStory(false);
        setSelectedStory(null);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const columns = [
        {
            title: GetWord('KULLANICI ADI & FOTOĞRAFI'),
            dataIndex: 'user_name',
            key: 'username',
            render: (text) => <div className='stories-table-users'>
                <div className='stories-table-users-profilephoto'></div>
                <span className='stories-table-users-text'>{text}</span>
            </div>,
        },
        {
            title: GetWord('HİKAYE'),
            dataIndex: 'story',
            key: 'story',
            render: (title, item) =>
                <button className='stories-table-story' onClick={() => viewStoryOpen(item)}>
                    <i className='icon-Eye stories-table-story-icon' />
                    <span className='stories-table-story-text'>{GetWord('Görüntüle')}</span>
                </button>,
        },
        {
            title: <div className='stories-table-date-title'>{GetWord('PAYLAŞIM TARİHİ')}</div>,
            dataIndex: 'date',
            key: 'date',
            render: (date, item) => (
                <div className='stories-table-date'>
                    <span className='stories-table-date-date'>{moment(date).format('LL')}</span>
                    <span className='stories-table-date-time'>{moment(date).format('LT')}</span>
                </div>
            ),
            sorter: (a, b) => a.date - b.date,
        },
        {
            title: GetWord('SEÇENEKLER'),
            key: 'options',
            render: (_, record) => (
                <Space size="middle">
                    <button className='stories-table-options-block'>
                        <i className='icon-Error' />
                    </button>
                    <button className='stories-table-options-delete'>
                        <i className='icon-Delete' />
                    </button>
                </Space>
            ),
        },
    ];

    return (
        <div className='stories'>
            <div className="stories-header">
                <p className='stories-header-title'>
                    {GetWord('Hikayeler')}
                </p>
            </div>

            <div className='stories-table'>
                <Table columns={columns} dataSource={currentPageData} pagination={false} />
            </div>

            <div className='pagination-container'>
                <span className='pagination-label'>{GetWord('Sayfa')}</span>
                <Pagination
                    className='stories-pagination'
                    current={currentPage}
                    pageSize={pageSize}
                    total={data?.totalCount}
                    onChange={handlePageChange}
                />
            </div>
            <Modal
                title={GetWord('Hikaye')}
                open={openViewStory}
                className='stories-viewstory'
                onCancel={noCancel}
                footer={null}
            >
                {selectedStory && <Viewstory story={selectedStory} />}
            </Modal>
        </div>
    )
}

export default Stories;
