import React, { useCallback, useEffect, useState } from 'react';
import './stations.scss';
import { GetWord } from "../../../../utils";
import { Space, Table, Pagination, Button, Modal } from 'antd';
import Service from '../../../../Service';
import Commentscount from './components/commentscount';
import { useNavigate } from 'react-router-dom';

const Stations = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [selectedId, setSelectedId] = useState(null);
    const [listData, setListData] = useState(null);
    const [stationId, setStationId] = useState(2245);
    const navigate = useNavigate();
    const [openCommentCount, setOpenCommentCount] = useState(false);
    const getStationListData = useCallback(() => {
        const service = new Service();
        const offset = (currentPage - 1) * pageSize;
        service.AdminService.getStationList(`?limit=${pageSize}&offset=${offset}&countryId=${stationId}`).then((res) => {
            console.log(res);
            setListData(res);
        })
    }, [currentPage, stationId]);

    useEffect(() => {
        getStationListData();
    }, [getStationListData]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const currentPageData = listData?.stations || [];

    const commentCountOpen = (value) => {
        setSelectedId(value);
        setOpenCommentCount(true);
    };

    const noCancel = () => {
        setOpenCommentCount(false);
    };


    const columns = [
        {
            title: GetWord('İSTASYON'),
            dataIndex: 'title',
            render: (text, data) => <div className='stations-table-station'>
                <button className='stations-table-station-eye' onClick={() => navigate("stationdetails/" + data.petrolStationId)}>
                    <i className='icon-Eye stations-table-station-eye-icon' />
                </button>
                <span className='stations-table-station-text'>{text}</span>
            </div>,
        },
        {
            title: GetWord('MARKALAR'),
            dataIndex: ['distributors', 'name'],
            render: (name) => <div className='stations-table-brand'>
                <span className='stations-table-brand-text'>{name}</span>
            </div>,
        },
        {
            title: GetWord('KONUM'),
            dataIndex: 'location',
            render: () => <div className='stations-table-location'>
                <i className='icon-Location-1 stations-table-location-icon' /><span className='stations-table-location-text'>
                    {GetWord('Adres')}
                </span>
            </div>,
        },
        {
            title: GetWord('YORUM SAYISI'),
            dataIndex: 'stationCommentCount',
            render: (title, data) => <div className='stations-table-numberofcomments'>
                <span className='stations-table-numberofcomments-text'>
                    {title === '0' ? GetWord('Yorum Yok') : title}
                </span>
                {title !== '0' && (
                    <>
                        <span className='stations-table-numberofcomments-text'>
                            ({data.avgRatings} Yıldız)
                        </span>
                        <Button className='stations-table-numberofcomments-button' onClick={() => commentCountOpen(data.petrolStationId)}>
                            <i className='icon-Eye stations-table-numberofcomments-button-icon' />
                            <span className='stations-table-numberofcomments-button-text'>{GetWord('Görüntüle')}</span>
                        </Button>
                    </>
                )}
            </div>,
        },
        {
            title: GetWord('HİZMETLER'),
            dataIndex: 'featureCount',
            render: (title) => <div className='stations-table-services'>
                <span className='stations-table-services-text'>
                    {title} {GetWord('Hizmet')}
                </span>
                <button className='stations-table-services-button'>
                    <i className='icon-Eye stations-table-services-button-icon' />
                    <span className='stations-table-services-button-text'>{GetWord('Görüntüle')}</span>
                </button>
            </div>,
        },
        {
            title: GetWord('SEÇENEKLER'),
            render: (data, record) => (
                <Space size="middle">
                    <button className='stations-table-options-edit'>
                        <i className='icon-Edit' />
                    </button>
                    <i className='icon-Delete stations-table-options-delete' />
                </Space>
            ),
        },
    ];

    return (
        <div className='stations'>
            <div className="station-header">
                <p className='stations-header-title'>
                    {GetWord('İstasyonlar')}
                </p>
            </div>

            <div className='stations-table'>
                <Table columns={columns} dataSource={currentPageData}
                    pagination={false}
                />
            </div>

            <div className='pagination-container'>
                <span className='pagination-label'>{GetWord('Sayfa')} </span>
                <Pagination
                    className='stations-pagination'
                    current={currentPage}
                    pageSize={pageSize}
                    total={listData?.totalCount}
                    onChange={handlePageChange}
                />
            </div>
            <div className='stations-modal'>
                <Modal title={GetWord('Yorum Sayısı')} open={openCommentCount} className=' stations-modal' onCancel={noCancel}
                    footer={null} >
                    <Commentscount selectedId={selectedId} />
                </Modal>
            </div>
        </div >
    )
}

export default Stations;
