import CampaignService from "./CampaignService";
import StationService from "./StationService";
import GeneralService from "./GeneralService";
import VisaService from "./VisaService";
import StaffService from "./StaffService";
import StoriesService from "./StoriesService";
import AdminService from "./Admin/AdminService";
import NotificationService from "./noticationService";

export default class Service {
  VisaService = new VisaService();
  CampaignService = new CampaignService();
  StationService = new StationService();
  GeneralService = new GeneralService();
  StaffService = new StaffService();
  StoriesService = new StoriesService();
  AdminService = new AdminService();
  NotificationService = new NotificationService();
}
