import baseService from './baseService';

export default class NotificationService extends baseService {
    constructor() {
        super();
        this.schemaName = "notifications";
    }


    getNotifications() {
        return this.getObject(null, "getNotifications");
    }

    delNotification(id) {
        return this.deleteObject(this.schemaName, "delete/" + id)
    }

    postNotificationGeneral(entity) {
        return this.postObject(this.schemaName, "all-user-send", entity)
    }

}