import React, { useCallback, useState } from 'react';
import '../userdetails.scss';
import { Button, Form, Input } from 'antd';
import { GetWord } from '../../../../../../utils';
import Service from '../../../../../../Service';
import { toast } from 'react-toastify';

const Editprofile = ({ selectedData, setShowEditProfileModalOpen, setIsRefreh }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(selectedData?.image);

    const onFinish = useCallback(async (values) => {
        const service = new Service();

        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('email', values.email);
        if (selectedFile) {
            formData.append('avatar', selectedFile);
        }

        try {
            const res = await service.AdminService.updateUser(selectedData?.uid, formData);
            if (!res.error) {
                toast.success("Success");
                setShowEditProfileModalOpen(false);
                setIsRefreh((prev) => !prev);
            } else {
                toast.error(res.message);
            }
        } catch (error) {
            toast.error("An error occurred while updating the profile.");
        }
    }, [selectedData?.uid, selectedFile, setIsRefreh, setShowEditProfileModalOpen]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setPreview(URL.createObjectURL(file));
    };

    return (
        <div className='userdetails-modal-editprofile'>
            <Form initialValues={selectedData} onFinish={onFinish}>
                <div className='userdetails-modal-editprofile-profilephoto'>
                    <img
                        name='avatar_url'
                        alt=''
                        src={preview}
                        className='userdetails-modal-editprofile-profilephoto-photo'
                    />
                    <input
                        type='file'
                        accept='image/*'
                        style={{ display: 'none' }}
                        id='fileInput'
                        onChange={handleFileChange}
                    />
                    <button
                        type='button'
                        className='userdetails-modal-editprofile-profilephoto-edit'
                        onClick={() => document.getElementById('fileInput').click()}
                    >
                        <i className='icon-Edit userdetails-modal-editprofile-profilephoto-edit-icon' />
                    </button>
                    <button className='userdetails-modal-editprofile-profilephoto-delete'>
                        <i className='icon-Delete userdetails-modal-editprofile-profilephoto-delete-icon' />
                    </button>
                </div>
                <div className='userdetails-modal-editprofile-namearea'>
                    <span className='userdetails-modal-editprofile-namearea-title'>{GetWord('İsim')}:</span>
                    <Form.Item className='userdetails-modal-editprofile-namearea-input' name='name'>
                        <Input placeholder='Ad Soyad' />
                    </Form.Item>
                </div>
                <div className='userdetails-modal-editprofile-emailarea'>
                    <span className='userdetails-modal-editprofile-emailarea-title'>{GetWord('E-Posta')}:</span>
                    <Form.Item className='userdetails-modal-editprofile-emailarea-input' name="email">
                        <Input placeholder='Email' />
                    </Form.Item>
                </div>
                <div className='userdetails-modal-editprofile-sendbutton'>
                    <Form.Item>
                        <Button key="submit" type="primary" htmlType="submit" className='userdetails-modal-editprofile-sendbutton-style'>
                            <span className='userdetails-modal-editprofile-sendbutton-text'>{GetWord('Gönder')}</span>
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default Editprofile;
