import { Button, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import React, { useState } from 'react';
import '../../notification.scss';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TextArea from 'antd/es/input/TextArea';
import { GetWord } from '../../../../../../utils';
import moment from 'moment';

const Personalized = () => {
    const [isShowNewNotificationModalOpen, setShowNewNotificationModalOpen] = useState(false);

    const showNewNotificationModal = () => {
        setShowNewNotificationModalOpen(true);
    };

    const handleOk = () => {
        setShowNewNotificationModalOpen(false);
    };

    const onChange = (date, dateString, time, timeString) => {
        console.log(date, dateString);
        console.log(time, timeString);
    };
    dayjs.extend(customParseFormat);

    const timeformat = 'HH:mm';
    const dateFormat = 'DD/MM/YYYY';
    const today = moment().format(dateFormat);
    const currentTime = moment().format(timeformat);


    return (
        <div className='notification-modal-personalized'>
            <Form>
                <div className='notification-modal-personalized-firstarea'>

                    <div className='notification-modal-personalized-targetgroup'>
                        <span className='notification-modal-personalized-targetgroup-title'>
                            {GetWord('Gönderilecek Kitle')}
                        </span>
                        <div className='notification-modal-personalized-targetgroup-selectarea'>
                            <div className='notification-modal-personalized-targetgroup-selectarea-personsarea'>
                                <span className='notification-modal-personalized-targetgroup-selectarea-personsarea-title'>
                                    {GetWord('Kişiler')}
                                </span>

                                <Form.Item className='notification-modal-personalized-targetgroup-selectarea-personsarea-select'>
                                    <Select
                                        defaultValue={'1'}
                                        options={[
                                            { value: '1', label: 'Everyone' },
                                            { value: '2', label: 'Everyone' },
                                            { value: '3', label: 'Everyone' },
                                        ]}
                                    />
                                </Form.Item>
                            </div>

                            <div className='notification-modal-personalized-targetgroup-selectarea-brandarea'>
                                <span className='notification-modal-personalized-targetgroup-selectarea-brandarea-title'>
                                    {GetWord('Marka')}
                                </span>

                                <Form.Item className='notification-modal-personalized-targetgroup-selectarea-brandarea-select'>
                                    <Select
                                        defaultValue={'1'}
                                        options={[
                                            { value: '1', label: 'All' },
                                            { value: '2', label: 'All' },
                                            { value: '3', label: 'All' },
                                        ]}
                                    />
                                </Form.Item>
                            </div>

                            <div className='notification-modal-personalized-targetgroup-selectarea-thisfuelarea'>
                                <span className='notification-modal-personalized-targetgroup-selectarea-thisfuelarea-title'>
                                    {GetWord('Şu Yakıtı Kullananlara')}
                                </span>

                                <Form.Item className='notification-modal-personalized-targetgroup-selectarea-thisfuelarea-select'>
                                    <Select
                                        defaultValue={'1'}
                                        options={[
                                            { value: '1', label: 'Fuel' },
                                            { value: '2', label: 'Diesel' },
                                            { value: '3', label: 'LPG' },
                                        ]}
                                    />
                                </Form.Item>
                            </div>

                            <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                                <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                    {GetWord('Bu Araç Sahiplerine')}
                                </span>

                                <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select'>
                                    <Select
                                        defaultValue={'1'}
                                        options={[
                                            { value: '1', label: 'Motorcycle' },
                                            { value: '2', label: 'Car' },
                                            { value: '3', label: 'Van' },
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>

                    <div className='notification-modal-personalized-content'>
                        <span className='notification-modal-personalized-content-title'>
                            {GetWord('İçerik')}
                        </span>
                        <div className='notification-modal-personalized-content-form'>
                            <div className='notification-modal-personalized-content-form-titleimage'>
                                <Form.Item className='notification-modal-personalized-content-input'>
                                    <Input placeholder={GetWord('Başlık')} />
                                </Form.Item>
                                <Form.Item className='notification-modal-personalized-content-url'
                                    name="url"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            type: 'url',
                                            warningOnly: true,
                                        },
                                        {
                                            type: 'string',
                                            min: 6,
                                        },
                                    ]}
                                >
                                    <Input placeholder={GetWord('Görsel URL')} />
                                </Form.Item>
                            </div>
                            <div className='notification-modal-personalized-content-form-content'>
                                <Form.Item className='notification-modal-personalized-content-textarea'>
                                    <TextArea rows={4}
                                        placeholder={GetWord('İçerik')}
                                        style={{
                                            resize: 'none',
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        </div>

                        <Form.Item className='notification-modal-personalized-content-url'
                            name="url"
                            rules={[
                                {
                                    required: true,
                                },
                                {
                                    type: 'url',
                                    warningOnly: true,
                                },
                                {
                                    type: 'string',
                                    min: 6,
                                },
                            ]}
                        >
                            <Input placeholder={GetWord('URL')} />
                        </Form.Item>
                    </div>
                </div>

                <div className='notification-modal-personalized-datetime'>
                    <span className='notification-modal-personalized-datetime-title'>
                        {GetWord('Gönderilecek Tarih & Saat')}
                    </span>
                    <div className='notification-modal-personalized-datetime-datetimearea'>
                        <div className='notification-modal-personalized-datetime-datetimearea-datearea'>
                            <span className='notification-modal-personalized-datetime-datetimearea-datearea-title'>
                                {GetWord('Tarih')}
                            </span>

                            <Form.Item className='notification-modal-personalized-datetime-datetimearea-datearea-select'>
                                <DatePicker onChange={onChange}
                                    format={dateFormat}
                                    placeholder={today}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-personalized-datetime-datetimearea-timearea'>
                            <span className='notification-modal-personalized-datetime-datetimearea-timearea-title'>
                                {GetWord('Saat')}
                            </span>

                            <Form.Item className='notification-modal-personalized-datetime-datetimearea-timearea-select'>
                                <TimePicker onChange={onChange}
                                    format={timeformat}
                                    placeholder={currentTime}

                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>

            <div className='notification-modal-personalized-content-sendbutton'>
                <Button key="submit" type="primary" onClick={handleOk} className='notification-modal-personalized-content-sendbutton-style'>
                    <span className='notification-modal-personalized-content-sendbutton-text'>
                        {GetWord('Gönder')}</span>
                </Button>
            </div>
        </div >
    );
};

export default Personalized;
