import React from 'react';
import { Form, Input, Select, DatePicker, TimePicker, Radio, Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetWord } from '../../../../../../utils';
import moment from 'moment';
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';

const { TextArea } = Input;

const NotificationMarket = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Form values:', values);
    };

    const onChange = (date, dateString, time, timeString) => {
        console.log(date, dateString);
        console.log(time, timeString);
    };
    dayjs.extend(customParseFormat);

    const timeformat = 'HH:mm';
    const dateFormat = 'DD/MM/YYYY';
    const today = moment().format(dateFormat);
    const currentTime = moment().format(timeformat);

    return (
        <div className='notification-modal-market'>
            <Form>
                <div className='notification-modal-market-content'>
                    <span className='notification-modal-market-content-title'>
                        {GetWord('İçerik')}
                    </span>
                    <div className='notification-modal-market-content-form'>
                        <div className='notification-modal-market-content-form-titleimage'>
                            <Form.Item className='notification-modal-market-content-input'>
                                <Input placeholder={GetWord('Başlık')} />
                            </Form.Item>
                            <Form.Item className='notification-modal-market-content-url'
                                name="url"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        type: 'url',
                                        warningOnly: true,
                                    },
                                    {
                                        type: 'string',
                                        min: 6,
                                    },
                                ]}
                            >
                                <Input placeholder={GetWord('Görsel URL')} />
                            </Form.Item>
                        </div>
                        <div className='notification-modal-market-content-form-content'>
                            <Form.Item className='notification-modal-market-content-textarea'>
                                <TextArea rows={4}
                                    placeholder={GetWord('İçerik')}
                                    style={{
                                        resize: 'none',
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='notification-modal-market-content-form-screen'>
                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Ekran Yönlendir')}
                            </span>

                            <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select'>
                                <Select
                                    defaultValue={'1'}
                                    options={[
                                        { value: '1', label: 'Fuel' },
                                        { value: '2', label: 'Diesel' },
                                        { value: '3', label: 'LPG' },
                                    ]}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Tür')}
                            </span>

                            <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select'>
                                <Select
                                    defaultValue={'1'}
                                    options={[
                                        { value: '1', label: 'Motorcycle' },
                                        { value: '2', label: 'Car' },
                                        { value: '3', label: 'Van' },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='notification-modal-market-content-form-screen-radio'>
                        <Form.Item className='notification-modal-market-content-form-screen-radio'>
                            <Checkbox.Group>
                                <Checkbox value={1}>{GetWord('Aracı Olanlar')}</Checkbox>
                                <Checkbox value={2}>{GetWord('Masraf Girişi Yapanlar')}</Checkbox>
                                <Checkbox value={3}>{GetWord('Son 7 Günde Aktif Olmayanlar')}</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                </div>


                <div className='notification-modal-campaign-datetime'>
                    <span className='notification-modal-campaign-datetime-title'>
                        {GetWord('Gönderilecek Tarih & Saat')}
                    </span>
                    <div className='notification-modal-campaign-datetime-datetimearea'>

                        <div className='notification-modal-campaign-datetime-datetimearea-datearea'>
                            <span className='notification-modal-campaign-datetime-datetimearea-datearea-title'>
                                {GetWord('Tarih')}
                            </span>

                            <Form.Item className='notification-modal-campaign-datetime-datetimearea-datearea-select'>
                                <DatePicker onChange={onChange}
                                    format={dateFormat}
                                    placeholder={today}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-campaign-datetime-datetimearea-timearea'>
                            <span className='notification-modal-campaign-datetime-datetimearea-timearea-title'>
                                {GetWord('Saat')}
                            </span>

                            <Form.Item className='notification-modal-campaign-datetime-datetimearea-timearea-select'>
                                <TimePicker onChange={onChange}
                                    format={timeformat}
                                    placeholder={currentTime}

                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
            <div className='notification-modal-campaign-content-sendbutton'>
                <Button key="submit" type="primary" className='notification-modal-campaign-content-sendbutton-style'>
                    <span className='notification-modal-campaign-content-sendbutton-text'>
                        {GetWord('Gönder')}</span>
                </Button>
            </div>
        </div>
    );
};

export default NotificationMarket;