import { Tabs } from 'antd'
import React, { useState } from 'react'
import Public from './components/public'
import Campaign from './components/campaign'
import Personalized from './components/personalized'
import Attractuser from './components/attractuser'
import { GetWord } from '../../../../../utils'
import NotificationMarket from './components/notificationMarket'
import NotificatioTimed from './components/NotificatioTimed'
import NotificationDependingOnVehicle from './components/NotificationDependingOnVehicle'

const Notificationmodal = ({ setSelectedTab, setIsRefresh, setModal }) => {
    const onChange = (key) => {
        setSelectedTab(key)
    };

    const items = [
        {
            key: '1',
            label: GetWord('Genel'),
            children: <Public setIsRefresh={setIsRefresh} setModal={setModal} />,
        },
        {
            key: '2',
            label: GetWord('Kampanya'),
            children: <Campaign />,
        },
        {
            key: '3',
            label: GetWord('Özelleştirilmiş'),
            children: <Personalized />,
        },
        {
            key: '4',
            label: GetWord('Kullanıcı Çekme'),
            children: <Attractuser />,
        },
        {
            key: '5',
            label: GetWord('Market'),
            children: <NotificationMarket />,
        },
        {
            key: '6',
            label: GetWord('Timed'),
            children: <NotificatioTimed />,
        },
        {
            key: '7',
            label: GetWord('DependingOnVehicle'),
            children: <NotificationDependingOnVehicle />,
        },
    ];
    return (
        <div>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
    )
}

export default Notificationmodal