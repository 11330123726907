import React, { useCallback, useState } from "react";
import { GetWord, setLocalstorage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { login } from "../../../utils/firebase";
import { Button, Checkbox, Form, Input } from "antd";

const Login = () => {
  const navigate = useNavigate();

  const onFinish = useCallback(async (values) => {
    const user = await login(values.email, values.password);
    if (user) {
      navigate("/");
    }
  }, [navigate])

  return (
    <div className="auth-content-form-login">
      <h3 className="auth-content-form-login-title">
        {GetWord("Login")}
      </h3>

      <Form
        name="basic"
        onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label=""
          name="email"
          className="auth-content-form-login-input"
          rules={[
            {
              required: true,
              message: GetWord("mailRequired"),
            },
          ]}
        >
          <Input prefix={<i className="icon-email" />} placeholder={GetWord("email")} />
        </Form.Item>

        <Form.Item
          label=""
          name="password"
          className="auth-content-form-login-input"
          rules={[
            {
              required: true,
              message: GetWord("passwordRequired"),
            },
          ]}
        >
          <Input.Password prefix={<i className="icon-lock" />} placeholder={GetWord("Password")} />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked" label={null}>
          <Checkbox>{GetWord("RememberMe")}</Checkbox>
        </Form.Item>

        <Form.Item className="auth-content-form-login-button" >
          <Button type="primary" htmlType="submit">
            {GetWord("Login")}
          </Button>
        </Form.Item>
      </Form>
    </div>

  );
};

export default Login;
