import { Table, Tag } from 'antd'
import React from 'react'
import { GetWord } from '../../../../../../../../utils';

const Daily = () => {

    const DailyColumns = [
        {
            title: GetWord('DURUM'),
            key: 'status',
            dataIndex: 'status',
            render: (_, { status }) => (
                <>
                    {status.map((status) => {
                        let color = status.length > 5 ? 'geekblue' : 'green';
                        if (status === '200 OK') {
                            color = 'cyan';
                        }
                        if (status === '404 WRN') {
                            color = 'yellow';
                        }
                        if (status === '500 ERR') {
                            color = 'red';
                        }
                        return (
                            <Tag color={color} key={status}>
                                {status}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: GetWord('İŞLEM'),
            dataIndex: 'process',
            key: 'process',
            render: (text) => <div className='userdetails-body-rightarea-eventhistory-daily-table-process'>
                <span className='userdetails-body-rightarea-eventhistory-daily-table-process-text'>{text}</span>
            </div >,
        },

        {
            title: <div className='userdetails-body-rightarea-eventhistory-daily-table-date-title'>{GetWord('TARİH & SAAT')}</div>,
            dataIndex: 'date',
            key: 'date',
            render: (date, data) => <div className='userdetails-body-rightarea-eventhistory-daily-table-date'>
                <span className='userdetails-body-rightarea-eventhistory-daily-table-date-date'>{date}</span>
                <span className='userdetails-body-rightarea-eventhistory-daily-table-date-time'>{data.time}</span>
            </div >,
            sorter: (a, b) => a.date - b.date,
        },


    ];

    const DailyData = [
        {
            key: '1',
            status: ['404 WRN'],
            process: 'POST /v1/customer/c_6677605d34d47/not_found',
            date: '05/28/2024',
            time: '12:00',
        },
        {
            key: '1',
            status: ['500 ERR'],
            process: 'POST /v1/invoice/in_4814_8266/invalid',
            date: '05/28/2024',
            time: '12:00',
        },
        {
            key: '1',
            status: ['200 OK'],
            process: 'POST /v1/invoices/in_5774_5905/payment',
            date: '05/28/2024',
            time: '12:00',
        },
        {
            key: '1',
            status: ['404 WRN'],
            process: 'POST /v1/customer/c_6677605d34d47/not_found',
            date: '05/28/2024',
            time: '12:00',
        },
        {
            key: '1',
            status: ['500 ERR'],
            process: 'POST /v1/invoice/in_4814_8266/invalid',
            date: '05/28/2024',
            time: '12:00',
        },
        {
            key: '1',
            status: ['200 OK'],
            process: 'POST /v1/invoices/in_5774_5905/payment',
            date: '05/28/2024',
            time: '12:00',
        },

    ];

    return (
        <div className='userdetails-body-rightarea-eventhistory-daily'>
            <div className='userdetails-body-rightarea-eventhistory-daily-title'>
                <span className='userdetails-body-rightarea-eventhistory-daily-title-text'>
                    {GetWord('Günlük')}
                </span>
                <button className='userdetails-body-rightarea-eventhistory-daily-title-button'>
                    <i className='icon-Check-Out userdetails-body-rightarea-eventhistory-daily-title-button-icon' />
                    <span className='userdetails-body-rightarea-eventhistory-daily-title-button-text'>
                        {GetWord('Raporu İndir')}
                    </span>
                </button>
            </div>
            <div className='userdetails-body-rightarea-eventhistory-daily-table'>
                <Table columns={DailyColumns} dataSource={DailyData}
                    pagination={{
                        pageSize: 3,
                    }}
                    footer={() => (
                        <button className='userdetails-body-rightarea-eventhistory-daily-table-footerbutton' >
                            <span className='userdetails-body-rightarea-eventhistory-daily-table-footerbutton-text'>{GetWord('Daha Fazla Göster')}</span>
                        </button>
                    )}
                />
            </div>
        </div>
    )
}

export default Daily