import React, { useCallback, useEffect, useState } from 'react';
import './stationdetails.scss';
import { GetWord } from '../../../../../utils';
import { Flex, Popover, Progress, Rate } from 'antd';
import Service from '../../../../../Service';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const Stationdetails = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [openIndex, setOpenIndex] = useState(null);

    const getStationDetailData = useCallback(() => {
        const service = new Service();
        service.AdminService.getStationDetail(id).then((res) => {
            res.combinedRatingCounts = res.combinedRatingCounts.reverse();
            setData(res);
        });
    }, [id]);

    useEffect(() => {
        getStationDetailData();
    }, [getStationDetailData]);


    const handleOpenChange = (isOpen, index) => {
        if (isOpen) {
            setOpenIndex(index);
        } else {
            setOpenIndex(null);
        }
    };


    return (
        <div className='stationdetails'>
            <div className='stationdetails-header'>
                <button className='stationdetails-header-backbutton'>
                    <i className='icon-Arrow-Left stationdetails-header-backbutton-icon' />
                </button>
                <span className='userdetails-header-prevpage'>{GetWord('İstasyonlar')}</span>
                <i className='icon-Arrow-Right stationdetails-header-arrowright' />
                <span className='userdetails-header-title'>{data?.title}</span>
            </div>

            <div className='stationdetails-body'>
                <div className='stationdetails-body-leftarea'>
                    <div className='stationdetails-body-leftarea-info'>
                        <img className='stationdetails-body-leftarea-info-image' alt='' src={data?.images?.imageUrl} />
                        <button className='stationdetails-body-leftarea-info-image-button'>
                            <i className='icon-Image stationdetails-body-leftarea-info-image-button-icon' />
                            <span className='stationdetails-body-leftarea-info-image-button-text'>{GetWord('Değiştir')}</span>
                        </button>

                        <div className='stationdetails-body-leftarea-info-namearea' >
                            <span className='stationdetails-body-leftarea-info-namearea-stationname'>{data?.title}</span>
                            <i className='icon-Edit stationdetails-body-leftarea-info-namearea-editbutton' />
                        </div>
                    </div>

                    <div className='stationdetails-body-leftarea-contact'>
                        <div className='stationdetails-body-leftarea-contact-phonenumber'>
                            <span className='stationdetails-body-leftarea-contact-phonenumber-title'>
                                {GetWord('Telefon Numarası')}
                            </span>
                            <span className='stationdetails-body-leftarea-contact-phonenumber-number'>
                                {data?.phone}
                            </span>
                            <button className='stationdetails-body-leftarea-contact-phonenumber-button'>
                                <i className='icon-Edit stationdetails-body-leftarea-contact-phonenumber-button-icon' />
                                <span className='stationdetails-body-leftarea-contact-phonenumber-button-text'>
                                    {GetWord('Düzenle')}
                                </span>
                            </button>
                        </div>
                        <div className='stationdetails-body-leftarea-contact-location'>
                            <span className='stationdetails-body-leftarea-contact-location-title'>
                                {GetWord('Konum')}
                            </span>
                            <span className='stationdetails-body-leftarea-contact-location-address'>
                                {data?.address}
                            </span>
                            <button className='stationdetails-body-leftarea-contact-location-button'>
                                <i className='icon-Edit stationdetails-body-leftarea-contact-location-button-icon' />
                                <span className='stationdetails-body-leftarea-contact-location-button-text'>
                                    {GetWord('Düzenle')}
                                </span>
                            </button>
                        </div>

                        <div className='stationdetails-body-leftarea-contact-lastedit'>
                            <span className='stationdetails-body-leftarea-contact-lastedit-title'>
                                {GetWord('Son Düzenleme')}
                            </span>
                            <span className='stationdetails-body-leftarea-contact-lastedit-date'>
                                {moment(data?.creationDate).format('LL')}
                            </span>
                        </div>
                    </div>
                </div>

                <div className='stationdetails-body-rightarea'>
                    <div className='stationdetails-body-rightarea-priceservice'>
                        <div className='stationdetails-body-rightarea-priceservice-pricearea'>
                            <div className='stationdetails-body-rightarea-priceservice-pricearea-titlearea'>
                                <span className='stationdetails-body-rightarea-priceservice-pricearea-titlearea-title'>
                                    {GetWord('Fiyatlar')}
                                </span>

                                <button className='stationdetails-body-rightarea-priceservice-pricearea-titlearea-button'>
                                    <i className='icon-Edit stationdetails-body-rightarea-priceservice-pricearea-titlearea-button-icon' />
                                    <span className='stationdetails-body-rightarea-priceservice-pricearea-titlearea-button-text'>
                                        {GetWord('Fiyatları Manuel Düzenle')}
                                    </span>
                                </button>

                            </div>

                            <div className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea'>
                                <div className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-fuel'>
                                    <span className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-fuel-price'>
                                        {data?.fuelPrices.find(fuel => fuel.name === 'Benzin')?.price || '0'} TL
                                    </span>
                                    <div className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-fuel-textarea'>
                                        <div className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-fuel-textarea-text'>
                                            <span className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-fuel-textarea-text-title'>
                                                {GetWord(data?.fuelPrices.find(fuel => fuel.name === 'Benzin')?.name)}
                                            </span>
                                            <span className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-fuel-textarea-text-desc'>
                                                {GetWord(data?.fuelPrices.find(fuel => fuel.name === 'Benzin')?.value)}
                                            </span>
                                        </div>

                                        <i className='icon-Gas-Station stationdetails-body-rightarea-priceservice-pricearea-fuelarea-fuel-textarea-icon' />
                                    </div>
                                </div>
                                <div className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-electric'>
                                    <span className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-electric-price'>
                                        {data?.fuelPrices.find(fuel => fuel.name === 'Elektrik')?.price || '0'} TL
                                    </span>
                                    <div className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-electric-textarea'>
                                        <div className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-electric-textarea-text'>
                                            <span className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-electric-textarea-text-title'>
                                                {GetWord(data?.fuelPrices.find(fuel => fuel.name === 'Elektrik')?.name)}
                                            </span>
                                            <span className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-electric-textarea-text-desc'>
                                                {GetWord(data?.fuelPrices.find(fuel => fuel.name === 'Elektrik')?.value)}
                                            </span>
                                        </div>

                                        <i className='icon-Flash stationdetails-body-rightarea-priceservice-pricearea-fuelarea-electric-textarea-icon' />
                                    </div>
                                </div>
                                <div className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-diesel'>
                                    <span className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-diesel-price'>
                                        {data?.fuelPrices.find(fuel => fuel.name === 'Motorin')?.price || '0'} TL
                                    </span>
                                    <div className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-diesel-textarea'>
                                        <div className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-diesel-textarea-text'>
                                            <span className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-diesel-textarea-text-title'>
                                                {GetWord(data?.fuelPrices.find(fuel => fuel.name === 'Motorin')?.name)}
                                            </span>
                                            <span className='stationdetails-body-rightarea-priceservice-pricearea-fuelarea-diesel-textarea-text-desc'>
                                                {GetWord(data?.fuelPrices.find(fuel => fuel.name === 'Motorin')?.value)}
                                            </span>
                                        </div>

                                        <i className='icon-GasCan stationdetails-body-rightarea-priceservice-pricearea-fuelarea-diesel-textarea-icon' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='stationdetails-body-rightarea-priceservice-servicearea'>
                            <div className='stationdetails-body-rightarea-priceservice-servicearea-titlearea'>
                                <span className='stationdetails-body-rightarea-priceservice-servicearea-titlearea-title'>
                                    {GetWord('Hizmetler')}
                                </span>

                                <button className='stationdetails-body-rightarea-priceservice-servicearea-titlearea-button'>
                                    <i className='icon-Edit stationdetails-body-rightarea-priceservice-servicearea-titlearea-button-icon' />
                                    <span className='stationdetails-body-rightarea-priceservice-servicearea-titlearea-button-text'>
                                        {GetWord('Hizmetleri Düzenle')}
                                    </span>
                                </button>

                            </div>
                            <div className='stationdetails-body-rightarea-priceservice-servicearea-services'>
                                {data && data.stationFeatures.map((item, index) => {
                                    if (item.title === 'toilet') {
                                        return (
                                            <div className='stationdetails-body-rightarea-priceservice-servicearea-services-style' key={index}>
                                                <i className='icon-Toilet stationdetails-body-rightarea-priceservice-servicearea-services-style-icon' />
                                                <span className='stationdetails-body-rightarea-priceservice-servicearea-services-style-text'>
                                                    {GetWord('Tuvalet')}
                                                </span>
                                            </div>
                                        )
                                    }
                                    else if (item.title === 'shop') {
                                        return (
                                            <div className='stationdetails-body-rightarea-priceservice-servicearea-services-style' key={index}>
                                                <i className='icon-Market stationdetails-body-rightarea-priceservice-servicearea-services-style-icon' />
                                                <span className='stationdetails-body-rightarea-priceservice-servicearea-services-style-text'>
                                                    {GetWord('Market')}
                                                </span>
                                            </div>
                                        )
                                    }
                                    else if (item.title === 'mosque') {
                                        return (
                                            <div className='stationdetails-body-rightarea-priceservice-servicearea-services-style'>
                                                <i className='icon-Mosque stationdetails-body-rightarea-priceservice-servicearea-services-style-icon' />
                                                <span className='stationdetails-body-rightarea-priceservice-servicearea-services-style-text'>
                                                    {GetWord('Mescid')}
                                                </span>
                                            </div>
                                        )
                                    }
                                    else if (item.title === 'carWash') {
                                        return (
                                            <div className='stationdetails-body-rightarea-priceservice-servicearea-services-style'>
                                                <i className='icon-Car-Wash stationdetails-body-rightarea-priceservice-servicearea-services-style-icon' />
                                                <span className='stationdetails-body-rightarea-priceservice-servicearea-services-style-text'>
                                                    {GetWord('Yıkama')}
                                                </span>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>

                    <div className='stationdetails-body-rightarea-commentrating'>
                        <div className='stationdetails-body-rightarea-commentrating-titlearea'>
                            <span className='stationdetails-body-rightarea-commentrating-titlearea-title'>
                                {GetWord('Değerlendirmeler ve Yorumlar')}
                            </span>
                            <div className='stationdetails-body-rightarea-commentrating-titlearea-star'>
                                <i className='icon-Star stationdetails-body-rightarea-commentrating-titlearea-star-icon' />
                                <span className='stationdetails-body-rightarea-commentrating-titlearea-star-text' >
                                    {data?.averageRating}
                                </span>
                            </div>
                        </div>

                        <div className='stationdetails-body-rightarea-commentrating-ratearea'>
                            <div className='stationdetails-body-rightarea-commentrating-ratearea-average'>
                                <span className='stationdetails-body-rightarea-commentrating-ratearea-average-title'>
                                    {GetWord("Ortalama")}
                                </span>
                                <div className='stationdetails-body-rightarea-commentrating-ratearea-average-countarea'>
                                    <span className='stationdetails-body-rightarea-commentrating-ratearea-average-countarea-rate'>
                                        {data?.averageRating}
                                    </span>
                                    <span className='stationdetails-body-rightarea-commentrating-ratearea-average-countarea-count'>
                                        {data?.totalFeedbackCount}
                                    </span>
                                </div>
                                {data && data.averageRating && <Rate disabled allowHalf defaultValue={parseFloat(data?.averageRating)} />}
                            </div>
                            <div className='stationdetails-body-rightarea-commentrating-ratearea-distribution'>
                                <span className='stationdetails-body-rightarea-commentrating-ratearea-distribution-title'>
                                    {GetWord("Dağılım")}
                                </span>
                                <Flex
                                    vertical
                                    gap="large"
                                >
                                    {data && data.combinedRatingCounts.map((item, index) => {
                                        const percent = (item.count / (data?.totalFeedbackCount)) * 100;

                                        return (
                                            <div key={index} className='stationdetails-body-rightarea-commentrating-ratearea-distribution-flex'>
                                                <span className='stationdetails-body-rightarea-commentrating-ratearea-distribution-flex-number'>{item.rating}</span>
                                                <Progress
                                                    percent={percent}
                                                    showInfo={false}
                                                    size="small"
                                                    className='stationdetails-body-rightarea-commentrating-ratearea-distribution-flex-line'
                                                />
                                                <span className='stationdetails-body-rightarea-commentrating-ratearea-distribution-flex-count'>{item.count}</span>
                                            </div>
                                        )
                                    })}
                                </Flex>
                            </div>
                        </div>

                        <div className='stationdetails-body-rightarea-commentrating-commentarea'>
                            <div className='stationdetails-body-rightarea-commentrating-commentarea-title'>
                                <span className='stationdetails-body-rightarea-commentrating-commentarea-title-text'>
                                    {GetWord("Yorumlar")} ({data?.totalCommentCount})
                                </span>
                            </div>

                            {data && data.lastComments.map((item, index) => {
                                return (
                                    <div className='stationdetails-body-rightarea-commentrating-commentarea-comment' key={index}>
                                        <div className='stationdetails-body-rightarea-commentrating-commentarea-comment-profilearea'>
                                            <div className='stationdetails-body-rightarea-commentrating-commentarea-comment-profilearea-profile'>
                                                <img className='stationdetails-body-rightarea-commentrating-commentarea-comment-profilearea-profile-photo' src='' alt='' />
                                                <span className='stationdetails-body-rightarea-commentrating-commentarea-comment-profilearea-profile-name'>{item?.userName}</span>
                                            </div>
                                            <Popover
                                                content={
                                                    <div className='stationdetails-popover'>
                                                        <div className='stationdetails-popover-removecomment'>
                                                            <i className='icon-Delete stationdetails-popover-removecomment-icon' />
                                                            <span className='stationdetails-popover-removecomment-text' >
                                                                {GetWord("Yorumu Kaldır")}
                                                            </span>
                                                        </div>
                                                        <button className='stationdetails-popover-showuser'>
                                                            <i className='icon-UserInfo stationdetails-popover-showuser-icon' />
                                                            <span className='stationdetails-popover-showuser-text' >
                                                                {GetWord("Kullanıcı Bilgilerini Göster")}
                                                            </span>
                                                        </button>
                                                    </div>
                                                }
                                                placement="bottomRight"
                                                trigger="click"
                                                onOpenChange={handleOpenChange}
                                                open={openIndex === index}
                                                onOpenChange={(isOpen) => handleOpenChange(isOpen, index)}
                                            >

                                                <button className='stationdetails-body-rightarea-commentrating-commentarea-comment-profilearea-moreicon'>
                                                    <i className='icon-More' />
                                                </button>
                                            </Popover>
                                        </div>
                                        <div className='stationdetails-body-rightarea-commentrating-commentarea-comment-average'>
                                            <span className='stationdetails-body-rightarea-commentrating-commentarea-comment-average-date'>{moment(item?.creationDate).format('LL')}</span>
                                        </div>

                                        <span className='stationdetails-body-rightarea-commentrating-commentarea-comment-comments-text'>
                                            {item?.comment}
                                        </span>
                                    </div>
                                )
                            })}

                            <div className='stationdetails-body-rightarea-commentrating-commentarea-comment-morebutton'>
                                <button className='stationdetails-body-rightarea-commentrating-commentarea-comment-morebutton-button'>
                                    <span className='stationdetails-body-rightarea-commentrating-commentarea-comment-morebutton-button-text'>
                                        Daha Fazla Yorum Göster
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Stationdetails;
