import React, { useEffect, useState } from "react";
import { Route, Router, Routes, useNavigate, useRoutes } from "react-router-dom";
import AdminLayout from "../layout/admin/AdminLayout";
import { getLocalstorage, setLocalstorage } from "../utils";
import { Decrypt, Encrypt } from "../utils/encrypted";
import Service from "../Service";
import LoadingComp from "../layout/shared/loading/loading";
import Notification from "../layout/admin/pages/notification/notification";
import Users from '../layout/admin/pages/users/users'
import UserDetails from "../layout/admin/pages/users/userdetails/userdetails";
import Stations from "../layout/admin/pages/stations/stations";
import StationDetails from '../layout/admin/pages/stations/stationdetails/stationdetails'
import Stories from "../layout/admin/pages/stories/stories";
import Weeklycar from "../layout/admin/pages/weeklycar/weeklycar";



const PrivateRouter = () => {
  const navigate = useNavigate();
  const user = getLocalstorage("user");

  useEffect(() => {
    if (!user) {
      navigate("/auth");
    }
  }, [user, navigate]);

  return user ? (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
        <Route index={true} element={<Notification />} />

        <Route path='/users'>
          <Route index element={<Users />} />
          <Route path='userdetails/:id' element={<UserDetails />} />
        </Route>
        <Route path='/stations'>
          <Route index element={<Stations />} />
          <Route path='stationdetails/:id' element={<StationDetails />} />
        </Route>
        <Route path='/stories' element={<Stories />} />
        <Route path='/weeklycar' element={<Weeklycar />} />
      </Route>
    </Routes>

  ) : null;
};

export default PrivateRouter;
