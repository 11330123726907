import { Button, notification } from 'antd'
import React, { useCallback } from 'react'
import '../notification.scss'
import { GetWord } from '../../../../../utils'
import Service from '../../../../../Service'


const Deletemodal = ({ handleCancel, id, setIsRefresh }) => {
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = useCallback((type, message, description) => {
        api[type]({
            message: message,
            description: description,
        });
    }, [api]);

    const deleteNotification = useCallback(() => {
        const service = new Service();
        service.NotificationService.delNotification(id).then((res) => {
            if (res && !res.error) {
                setIsRefresh((prev) => !prev);
                openNotificationWithIcon("success", "Başarılı", "Bildirim başarılı bir şekilde silindi.");
                handleCancel();
            } else {
                openNotificationWithIcon("error", "Hata", res?.message);

            }
        })
    }, [handleCancel, id, openNotificationWithIcon, setIsRefresh])


    return (
        <div>
            {contextHolder}
            <div className='notification-deletemodal'>
                <Button className='notification-deletemodal-delete' onClick={() => deleteNotification()}>
                    <span className='notification-deletemodal-delete-text'>
                        {GetWord('Sil')}
                    </span>
                </Button>
                <Button className='notification-deletemodal-cancel' onClick={() => handleCancel()}>
                    <span className='notification-deletemodal-delete-cancel'>
                        {GetWord('İptal')}
                    </span>
                </Button>
            </div>
        </div>
    )
}

export default Deletemodal