import React from 'react'
import '../stories.scss'

const Viewstory = ({ data }) => {
    return (
        <div className='stories-viewstory'>
            <div className='stories-viewstory-body'>
                {data && <img src={data.storyUrl} alt="Story" className='stories-viewstory-image' />}
            </div>
            <div className='stories-viewstory-buttonarea'>
                <button className='stories-viewstory-buttonarea-button-block'>
                    <i className='icon-Error' />
                </button>
                <button className='stories-viewstory-buttonarea-button-delete'>
                    <i className='icon-Delete' />
                </button>
            </div>
        </div>
    )
}

export default Viewstory;
