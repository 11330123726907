import { Input } from 'antd'
import React from 'react'
import LinkedAccounts from './linkedaccounts'
import { GetWord } from '../../../../../../../utils'

const Security = () => {
    return (
        <div className='userdetails-body-rightarea-security'>
            <div className='userdetails-body-rightarea-security-profile'>
                <div className='userdetails-body-rightarea-security-profile-title'>
                    <span className='userdetails-body-rightarea-security-profile-title-text'>
                        {GetWord('Profil')}
                    </span>
                </div>

                <div className='userdetails-body-rightarea-security-profile-security'>
                    <div className='userdetails-body-rightarea-security-profile-security-email'>
                        <div className='userdetails-body-rightarea-security-profile-security-email-title'>
                            <span className='userdetails-body-rightarea-security-profile-security-email-title-text'>
                                {GetWord('E-Posta')}
                            </span>
                        </div>
                        <div className='userdetails-body-rightarea-security-profile-security-email-inputbutton'>
                            <Input placeholder="Basic usage" className='userdetails-body-rightarea-security-profile-security-email-inputbutton-input' />
                            <button className='userdetails-body-rightarea-security-profile-security-email-inputbutton-button'>
                                <i className='icon-Edit userdetails-body-rightarea-security-profile-security-email-inputbutton-button-icon' />
                                <span className='userdetails-body-rightarea-security-profile-security-email-inputbutton-button-text'>
                                    {GetWord('Düzenle')}
                                </span>
                            </button>

                        </div>

                    </div>
                    <div className='userdetails-body-rightarea-security-profile-security-password'>
                        <div className='userdetails-body-rightarea-security-profile-security-password-title'>
                            <span className='userdetails-body-rightarea-security-profile-security-password-title-text'>
                                {GetWord('Şifre')}
                            </span>
                        </div>
                        <div className='userdetails-body-rightarea-security-profile-security-password-inputbutton'>
                            <Input placeholder="Basic usage" className='userdetails-body-rightarea-security-profile-security-password-inputbutton-input' />
                            <button className='userdetails-body-rightarea-security-profile-security-password-inputbutton-button'>
                                <i className='icon-Edit userdetails-body-rightarea-security-profile-security-password-inputbutton-button-icon' />
                                <span className='userdetails-body-rightarea-security-profile-security-password-inputbutton-button-text'>
                                    {GetWord('Düzenle')}
                                </span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>
            <div className='userdetails-body-rightarea-security-accounts'>
                <div className='userdetails-body-rightarea-security-accounts-title'>
                    <span className='userdetails-body-rightarea-security-accounts-title-text'>
                        {GetWord('Bağlı Hesaplar')}
                    </span>
                    <span className='userdetails-body-rightarea-security-accounts-title-subtext'>
                        (1)
                    </span>
                </div>
                <div>
                    <LinkedAccounts />
                </div>
            </div>
        </div>
    )
}

export default Security