import { Switch } from 'antd';
import React, { useState } from 'react';
import { GetWord } from '../../../../../../../utils';

const LinkedAccounts = () => {

    const accountsList = [
        {
            "logo": "https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg",
            "accountName": "Google",
            "initialConnection": true,
        },
        {
            "logo": "https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg",
            "accountName": "Google",
            "initialConnection": true,
        },
        {
            "logo": "https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg",
            "accountName": "Google",
            "initialConnection": true,
        },
        {
            "logo": "https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg",
            "accountName": "Google",
            "initialConnection": true,
        },
    ];

    return (
        <div>
            {accountsList.map((account, i) => (
                <LinkedAccountItem key={i} account={account} />
            ))}
        </div>
    );
};

const LinkedAccountItem = ({ account }) => {
    const [isConnected, setIsConnected] = useState(account.initialConnection);

    const toggleConnection = () => {
        setIsConnected(!isConnected);
    };

    return (
        <div className='userdetails-body-rightarea-security-accounts-linkedaccounts'>
            <div className='userdetails-body-rightarea-security-accounts-linkedaccounts-account'>
                <div className='userdetails-body-rightarea-security-accounts-linkedaccounts-account-title'>
                    <img src={account.logo} className='userdetails-body-rightarea-security-accounts-linkedaccounts-account-title-logo' />
                    <div className='userdetails-body-rightarea-security-accounts-linkedaccounts-account-title-name'>
                        {account.accountName}
                    </div>
                </div>
                <div className='userdetails-body-rightarea-security-accounts-linkedaccounts-account-connection'>
                    <span className='userdetails-body-rightarea-security-accounts-linkedaccounts-account-connection-text'>
                        {GetWord('Bağlantı')}:
                        <span className='userdetails-body-rightarea-security-accounts-linkedaccounts-account-connection-subtext'>
                            {isConnected ? GetWord('Bağlı') : GetWord('Bağlı Değil')}
                        </span>
                    </span>
                    <Switch checked={isConnected} onChange={toggleConnection} />
                </div>
            </div>
        </div>
    );
};

export default LinkedAccounts;
