import React from 'react'
const carlist = ({ data }) => {

    const carlist = [
        {

            "title": "BMW M3",
            "fuel": "Gasoline",
            "date": "2020",
            "plate": "52 ABA 123",
        },
        {

            "title": "BMW M3",
            "fuel": "Gasoline",
            "date": "2020",
            "plate": "34 EJS 113",
        },
        {

            "title": "BMW M3",
            "fuel": "Gasoline",
            "date": "2020",
            "plate": "52 ABA 123",
        },
        {

            "title": "BMW M3",
            "fuel": "Gasoline",
            "date": "2020",
            "plate": "52 ABA 123",
        },
    ]
    return (
        <div className='userdetails-body-rightarea-overview-cars-carsarea'>
            {data && data?.vehicles.map((car, i) => (
                <div className='userdetails-body-rightarea-overview-cars-carsarea-car'>
                    <div className='userdetails-body-rightarea-overview-cars-carsarea-car-carinfo'>
                        <div className='userdetails-body-rightarea-overview-cars-carsarea-car-carinfo-title'>
                            <i className='icon-Car userdetails-body-rightarea-overview-cars-carsarea-car-carinfo-title-icon' />
                            <span className='userdetails-body-rightarea-overview-cars-carsarea-car-carinfo-title-carname'>
                                {car.carBrandName}  {car.carModelName}
                            </span>
                        </div>
                        <div className='userdetails-body-rightarea-overview-cars-carsarea-car-carinfo'>
                            <div className='userdetails-body-rightarea-overview-cars-carsarea-car-carinfo-info'>
                                <div className='userdetails-body-rightarea-overview-cars-carsarea-car-carinfo-info-fuel'>
                                    <i className='icon-Gas-Station userdetails-body-rightarea-overview-cars-carsarea-car-carinfo-info-fuel-icon ' />
                                    <span className='userdetails-body-rightarea-overview-cars-carsarea-car-carinfo-info-fuel-text'>
                                        {car.fuelTypeName}
                                    </span>
                                </div>
                                <div className='userdetails-body-rightarea-overview-cars-carsarea-car-carinfo-info-date'>
                                    <i className='icon-Calendar userdetails-body-rightarea-overview-cars-carsarea-car-carinfo-info-date-icon ' />
                                    <span className='userdetails-body-rightarea-overview-cars-carsarea-car-carinfo-info-date-text'>
                                        {car.year}
                                    </span>
                                </div>

                                <div className='userdetails-body-rightarea-overview-cars-carsarea-car-carinfo-info-plate'>
                                    <i className='icon-Plate userdetails-body-rightarea-overview-cars-carsarea-car-carinfo-info-plate-icon ' />
                                    <span className='userdetails-body-rightarea-overview-cars-carsarea-car-carinfo-info-plate-text'>
                                        {car.licensePlate}
                                    </span>
                                </div>


                            </div>

                        </div>
                    </div>

                    <button className='userdetails-body-rightarea-overview-cars-carsarea-car-eye'>
                        <i className='icon-Eye userdetails-body-rightarea-overview-cars-carsarea-car-eye-icon' />
                    </button>
                </div>
            ))}
        </div>
    )
}

export default carlist