import { Button, Checkbox, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import React, { useState } from 'react';
import '../../notification.scss';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TextArea from 'antd/es/input/TextArea';
import { GetWord } from '../../../../../../utils';
import moment from 'moment';

const Attractuser = () => {
    const [isShowNewNotificationModalOpen, setShowNewNotificationModalOpen] = useState(false);

    const showNewNotificationModal = () => {
        setShowNewNotificationModalOpen(true);
    };

    const handleOk = () => {
        setShowNewNotificationModalOpen(false);
    };

    const onChange = (date, dateString, time, timeString) => {
        console.log(date, dateString);
        console.log(time, timeString);
    };
    dayjs.extend(customParseFormat);

    const timeformat = 'HH:mm';
    const dateFormat = 'DD/MM/YYYY';
    const today = moment().format(dateFormat);
    const currentTime = moment().format(timeformat);


    return (
        <div className='notification-modal-attractuser'>
            <Form>
                <div className='notification-modal-attractuser-targetgroup'>
                    <span className='notification-modal-attractuser-targetgroup-title'>
                        {GetWord('Gönderilecek Kitle')}
                    </span>
                    <div className='notification-modal-attractuser-targetgroup-selectarea'>
                        <div className='notification-modal-attractuser-targetgroup-selectarea-personsarea'>

                            <Form.Item className='notification-modal-attractuser-targetgroup-selectarea-personsarea-select'>
                                <Checkbox
                                    defaultValue={'1'}
                                >
                                    Henüz hiç kayıtlı aracı olmayan
                                </Checkbox>
                            </Form.Item>
                        </div>

                        <div className='notification-modal-attractuser-targetgroup-selectarea-brandarea'>
                            <span className='notification-modal-attractuser-targetgroup-selectarea-brandarea-title'>
                                {GetWord('Marka')}
                            </span>

                            <Form.Item className='notification-modal-attractuser-targetgroup-selectarea-brandarea-select'>
                                <Select
                                    defaultValue={'1'}
                                    options={[
                                        { value: '1', label: 'All' },
                                        { value: '2', label: 'All' },
                                        { value: '3', label: 'All' },
                                    ]}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-attractuser-targetgroup-selectarea-thisfuelarea'>
                            <span className='notification-modal-attractuser-targetgroup-selectarea-thisfuelarea-title'>
                                {GetWord('Şu Yakıtı Kullananlara')}
                            </span>

                            <Form.Item className='notification-modal-attractuser-targetgroup-selectarea-thisfuelarea-select'>
                                <Select
                                    defaultValue={'1'}
                                    options={[
                                        { value: '1', label: 'Fuel' },
                                        { value: '2', label: 'Diesel' },
                                        { value: '3', label: 'LPG' },
                                    ]}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-attractuser-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-attractuser-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Bu Araç Sahiplerine')}
                            </span>

                            <Form.Item className='notification-modal-attractuser-targetgroup-selectarea-ownersarea-select'>
                                <Select
                                    defaultValue={'1'}
                                    options={[
                                        { value: '1', label: 'Motorcycle' },
                                        { value: '2', label: 'Car' },
                                        { value: '3', label: 'Van' },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className='notification-modal-attractuser-content'>
                    <span className='notification-modal-attractuser-content-title'>
                        {GetWord('İçerik')}
                    </span>
                    <div className='notification-modal-attractuser-content-form'>
                        <div className='notification-modal-attractuser-content-form-titleimage'>
                            <Form.Item className='notification-modal-attractuser-content-input'>
                                <Input placeholder={GetWord('Başlık')} />
                            </Form.Item>
                            <Form.Item className='notification-modal-attractuser-content-url'
                                name="url"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        type: 'url',
                                        warningOnly: true,
                                    },
                                    {
                                        type: 'string',
                                        min: 6,
                                    },
                                ]}
                            >
                                <Input placeholder={GetWord('Görsel URL')} />
                            </Form.Item>
                        </div>
                        <div className='notification-modal-attractuser-content-form-content'>
                            <Form.Item className='notification-modal-attractuser-content-textarea'>
                                <TextArea rows={4}
                                    placeholder={GetWord('İçerik')}
                                    style={{
                                        resize: 'none',
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <Form.Item className='notification-modal-attractuser-content-url'
                        name="url"
                        rules={[
                            {
                                required: true,
                            },
                            {
                                type: 'url',
                                warningOnly: true,
                            },
                            {
                                type: 'string',
                                min: 6,
                            },
                        ]}
                    >
                        <Input placeholder={GetWord('URL')} />
                    </Form.Item>
                </div>

                <div className='notification-modal-attractuser-datetime'>
                    <span className='notification-modal-attractuser-datetime-title'>
                        {GetWord('Gönderilecek Tarih & Saat')}
                    </span>
                    <div className='notification-modal-attractuser-datetime-datetimearea'>
                        <div className='notification-modal-attractuser-datetime-datetimearea-datearea'>
                            <span className='notification-modal-attractuser-datetime-datetimearea-datearea-title'>
                                {GetWord('Tarih')}
                            </span>

                            <Form.Item className='notification-modal-attractuser-datetime-datetimearea-datearea-select'>
                                <DatePicker onChange={onChange}
                                    format={dateFormat}
                                    placeholder={today}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-attractuser-datetime-datetimearea-timearea'>
                            <span className='notification-modal-attractuser-datetime-datetimearea-timearea-title'>
                                {GetWord('Saat')}
                            </span>

                            <Form.Item className='notification-modal-attractuser-datetime-datetimearea-timearea-select'>
                                <TimePicker onChange={onChange}
                                    format={timeformat}
                                    placeholder={currentTime}

                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>

            <div className='notification-modal-attractuser-content-sendbutton'>
                <Button key="submit" type="primary" onClick={handleOk} className='notification-modal-attractuser-content-sendbutton-style'>
                    <span className='notification-modal-attractuser-content-sendbutton-text'>
                        {GetWord('Gönder')}</span>
                </Button>
            </div>
        </div >
    );
};

export default Attractuser;
