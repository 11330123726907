import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SidebarItem from "./SideBarItem";
import LoadingComp from "../../../shared/loading/loading";
import './scss/sidebar.scss';
import { GetWord } from "../../../../utils";
import SideBarItemv2 from "./SideBarItemv2";

const datas = [
  {
    id: 1,
    isHeader: true,
    title: GetWord('Genel'),
  },
  {
    id: 11,
    isHeader: false,
    title: GetWord('Bildirimler'),
    icon: <i className="icon-Notification-Add" />,
    pathname: "/",
  },
  {
    id: 12,
    isHeader: false,
    title: GetWord('Kullanıcılar'),
    icon: <i className="icon-User" />,
    pathname: "/users",
  },
  {
    id: 13,
    isHeader: false,
    title: GetWord('Firmalar'),
    icon: <i className="icon-Office" />,
    pathname: "/companies",
    submenu:
      [
        { id: 131, title: 'Firmalar', pathname: '/companies' },
        { id: 132, title: 'İstasyonlar', pathname: '/stations' },
        { id: 133, title: 'Lastikçiler', pathname: '/tyre' },
        { id: 134, title: 'Akücüler', pathname: '/batteries' }
      ]
  },
  {
    id: 14,
    isHeader: false,
    title: GetWord('Masraflar'),
    icon: <i className="icon-Wallet" />,
    pathname: "/expenses",
  },
  {
    id: 15,
    isHeader: false,
    title: GetWord('Petros Puanı'),
    icon: <i className="icon-Gas-Station" />,
    pathname: "/petrospoint",
  },


  {
    id: 2,
    isHeader: true,
    title: GetWord('Uygulama İçi'),
  },
  {
    id: 21,
    isHeader: false,
    title: GetWord('Anasayfa'),
    icon: <i className="icon-Home" />,
    pathname: "/home",
  },
  {
    id: 22,
    isHeader: false,
    title: GetWord('Hikayeler'),
    icon: <i className="icon-Story" />,
    pathname: "/stories",
  },
  {
    id: 23,
    isHeader: false,
    title: GetWord('Market'),
    icon: <i className="icon-Store" />,
    pathname: "/market",
  },
  {
    id: 24,
    isHeader: false,
    title: GetWord('Haftanın Araçları'),
    icon: <i className="icon-Car" />,
    pathname: "/weeklycar",
  },



  // // {
  // //   id: 7,
  // //   isHeader: false,
  // //   title: "Grafikler",
  // //   icon: <riIcon.RiPieChartLine className="nav-icon" />,
  // //   pathname: "/graphics",
  // // },
  // {
  //   id: 8,
  //   isHeader: false,
  //   title: "detail",
  //   icon: <giIcon.GiHamburgerMenu className="nav-icon" />,
  //   pathname: "/detail",
  // },
  // // {
  // //   id: 9,
  // //   isHeader: false,
  // //   title: "Konum",
  // //   icon: <biIcon.BiMapAlt className="nav-icon" />,
  // //   pathname: "/location",
  // // },
  // {
  //   id: 10,
  //   isHeader: true,
  //   title: "Extras",
  // },
  // {
  //   id: 11,
  //   isHeader: false,
  //   title: "settings",
  //   icon: <riIcon.RiSettings2Line className="nav-icon" />,
  //   pathname: "/settings",
  // },
  // // {
  // //   id: 12,
  // //   isHeader: false,
  // //   title: "customerloyaltyindex",
  // //   icon: <riIcon.RiPieChartLine className="nav-icon" />,
  // //   pathname: "/customerloyaltyindex",
  // // },
];


const Sidebar = () => {
  const [loading, setLoading] = useState(false);
  const [isCompaniesOpen, setIsCompaniesOpen] = useState(false);
  const refMenu = useRef(null);
  const refItem = useRef(null);

  const { sideBtn } = useSelector((state) => state.site);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refMenu.current &&
        !refMenu.current.contains(event.target) &&
        refItem.current &&
        !refItem.current.contains(event.target)
      ) {
        setIsCompaniesOpen(false);
      }
    };
    document.getElementById("sidebar").addEventListener("mousedown", handleClickOutside);

    return () => {
      if (document.getElementById("sidebar"))
        document.getElementById("sidebar").removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (loading) {
    return <LoadingComp />;
  }



  return (
    <div id="sidebar">
      <aside className={"sidebar " + (sideBtn ? "active" : "")}>
        <nav className="sidebar-nav">
          {datas.map((item, index) => {
            if (item.id === 13) {
              return (
                <div key={index} ref={refMenu}>
                  <div className={"sidebar-nav-link " + (isCompaniesOpen ? "active" : "")} onClick={() => setIsCompaniesOpen((prev) => !prev)}>
                    {item.icon}
                    <span className="sidebar-nav-title">{GetWord(item.title)}</span>
                    <div className="sidebar-nav-drop-container" style={{ transform: isCompaniesOpen ? "rotate(90deg)" : "rotate(0deg)" }}>
                      <i className="icon-Arrow-Right sidebar-nav-drop" />
                    </div>
                  </div>
                  {isCompaniesOpen && item.submenu && (
                    <div className="sidebar-submenu">
                      {item.submenu.map((subItem, subIndex) => (
                        <SideBarItemv2
                          key={subIndex}
                          pathname={subItem.pathname}
                          title={subItem.title}
                          refItem={refItem}
                        />
                      ))}
                    </div>
                  )}
                </div>
              );
            }
            return (
              <SidebarItem
                pathname={item.pathname}
                icon={item.icon}
                title={item.title}
                isHeader={item.isHeader}
                key={index}
              />
            );
          })}
        </nav>
      </aside>
      <div className={"sidebar-copyright " + (sideBtn ? "active" : "")}>
        <span className="sidebar-copyright-text">Copyright © 2021 Petros</span>
        <span className="sidebar-copyright-text">All rights reserved.</span>
      </div>
    </div>
  );
};

export default memo(Sidebar);