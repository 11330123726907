import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import { GetWord } from "../../../../utils";
import './scss/sidebar.scss';

const SidebarItemv2 = ({ isHeader, title, icon, pathname, onClick, refItem }) => {
  return (
    <React.Fragment>
      {isHeader ? (
        <div className="sidebar-nav-header"><span>{GetWord(title)}</span></div>
      ) : (
        <NavLink className="sidebar-nav-link" to={pathname} onClick={onClick} ref={refItem}>
          {icon}
          <span className="sidebar-nav-title">{GetWord(title)}</span>
          <div className="sidebar-nav-drop-container">
            <i className="icon-Arrow-Right sidebar-nav-drop" />
          </div>
        </NavLink>
      )}
    </React.Fragment>
  );
};

export default memo(SidebarItemv2);
