import React from "react";
import { Outlet } from "react-router-dom";
import './scss/auth.scss'

const AuthLayout = () => {
  return (
    <main className="auth">
      <div className="auth-background">
        <div className="auth-content">
          <h3 className="auth-content-title">Petros Admin</h3>
          <div className="auth-content-form">
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
};

export default AuthLayout;
